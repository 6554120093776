body {
  overflow: scroll;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-preview {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  --webkit-transform-style: preserve-3d;
  display: none;
}

.video-preview-canvas {
  background-color: transparent;
  /* width: 100vw; */
  /* height: 100vh; */
  max-width: 100%;
  max-height: 100%;
  /* position: absolute;
  top: 0px;
  left: 0px; */
  z-index: 1000;
}

.twitter {
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.twitter a {
  color: white;
}
/* .stats-panel {
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  bottom: 0px;
  color: white;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.stats-panel div {
  margin-left: 10px;
  margin-right: 10px;
} */
